import { Vue, Options } from 'vue-class-component';
import { PageMenu } from '@/core/models/side-menu';
import {
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonText,
    IonMenuToggle,
    menuController,
} from '@ionic/vue';
import { PropType } from 'vue';
import {
    chevronForwardOutline,
    chevronForwardSharp,
    syncOutline,
    syncSharp,
} from 'ionicons/icons';
import { PAGE_PLANT_SELECTOR } from '@/core/router/pages';
import { mapState } from 'vuex';
import {
    PlantReferenceDTO,
    UsermeDTO,
} from 'client-generator/generated-clients/client';
import { LOGOUT } from '@/core/store/action-types';

@Options({
    name: 'SideMenu',
    components: {
        IonIcon,
        IonItem,
        IonLabel,
        IonList,
        IonText,
        IonMenuToggle,
    },
    props: {
        appPages: {
            type: Object as PropType<PageMenu[]>,
            required: true,
        },
    },
    computed: {
        ...mapState(['profile', 'plantList', 'currentPlant']),
    },
    emits: ['close-menu'],
})
export default class SideMenu extends Vue {
    profile!: UsermeDTO;
    appPages!: PageMenu[];
    currentPlant!: PlantReferenceDTO | null;
    plantList!: PlantReferenceDTO[];

    chevronForwardOutline = chevronForwardOutline;
    chevronForwardSharp = chevronForwardSharp;
    syncOutline = syncOutline;
    syncSharp = syncSharp;

    selectedIndex = 0;

    async goToPlantSelector() {
        this.$router.push({ name: PAGE_PLANT_SELECTOR });
        await menuController.toggle();
    }

    isSelected(url: string): string {
        return url === this.$route.path ? 'selected' : '';
    }

    doLogout() {
        this.$store.dispatch(LOGOUT);
    }

    doClose() {
        this.$emit('close-menu');
    }
}
