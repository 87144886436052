import { Vue, Options } from 'vue-class-component';
import { PageMenu } from '@/core/models/side-menu';
import SideMenu from '@/views/components/SideMenu/SideMenu.vue';

import {
    IonApp,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    menuController,
} from '@ionic/vue';

import {
    scanCircleOutline,
    scanCircleSharp,
    calendarOutline,
    calendarSharp,
} from 'ionicons/icons';
import { LOGOUT, SET_APP_LOADING } from '@/core/store/action-types';
import { PAGE_LOGIN } from '@/core/router/pages';
import i18n from '@/core/translations/i18n';

@Options({
    name: 'MainContainer',
    components: {
        IonApp,
        IonList,
        IonListHeader,
        IonMenu,
        IonMenuToggle,
        IonNote,
        IonRouterOutlet,
        IonSplitPane,
        SideMenu,
    },
})
export default class MainContainer extends Vue {
    scanCircleOutline = scanCircleOutline;
    scanCircleSharp = scanCircleSharp;
    calendarOutline = calendarOutline;
    calendarSharp = calendarSharp;
    SideMenu = SideMenu;

    appPages: PageMenu[] = [
        {
            title: i18n.global.t('menu.dashboard'),
            url: '/dashboard',
            iconType: 'fontawesome',
            iosIcon: 'badge-check',
            mdIcon: ['fas', 'badge-check'],
        },
        {
            title: i18n.global.t('menu.planning'),
            url: '/sales-planning',
            iconType: 'fontawesome',
            iosIcon: 'calendar-check',
            mdIcon: 'calendar-check',
        },
        {
            title: i18n.global.t('menu.archive'),
            url: 'sales-archive',
            iconType: 'fontawesome',
            iosIcon: 'archive',
            mdIcon: 'archive',
        },
    ];

    mounted() {
        this.$emitter.on('do-show-menu', this.doShowMenu);
    }

    doLogout() {
        this.$store.dispatch(LOGOUT);

        this.$store.dispatch(SET_APP_LOADING, true);

        setTimeout(async () => {
            await this.$router.replace({
                name: PAGE_LOGIN,
            });
            this.$store.dispatch(SET_APP_LOADING, false);
        }, 300);
    }

    doShowMenu() {
        menuController.open('start');
    }

    doCloseMenu() {
        menuController.close('start');
    }
}
