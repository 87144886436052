<template>
    <IonSplitPane content-id="main-content">
        <ion-menu content-id="main-content" type="overlay" side="start">
            <ion-content mode="md">
                <SideMenu :app-pages="appPages" @close-menu="doCloseMenu" />
            </ion-content>
        </ion-menu>
        <router-view id="main-content"></router-view>
    </IonSplitPane>
</template>

<script lang="ts" src="./MainContainer.ts"></script>

<style lang="scss" src="./MainContainer.scss"></style>
