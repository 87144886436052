<template>
    <div class="sidemenu">
        <div
            class="d-flex align-items-center justify-content-between my-4 px-4"
        >
            <img
                src="~@/assets/images/logo.png"
                alt="FuttaMe Logo"
                class="sidemenu__logo"
            />

            <button class="bg-transparent" @click="doClose">
                <font-awesome-icon :icon="['fal', 'times']" size="2x" />
            </button>
        </div>

        <div class="sidemenu__place-changer bg-dark px-2">
            <div slot="start" class="d-flex align-items-center px-2 py-2">
                <img
                    src="~@/assets/images/icona-stabilimento.png"
                    alt="stabilimento"
                    class="ml-2 mr-2"
                    border="0"
                />
                <ion-text class="fs-14 d-block w-100" v-if="currentPlant">
                    <span class="text-borders">{{ $t("general.plant") }}</span
                    ><br />
                    <span class="text-white text-uppercase">{{
                        currentPlant.name
                    }}</span>
                </ion-text>
                <button
                    v-if="plantList && plantList.length > 1"
                    class="bg-transparent"
                >
                    <font-awesome-icon
                        @click="goToPlantSelector()"
                        icon="exchange-alt"
                        class="text-primary fs-20"
                    />
                </button>
            </div>
        </div>
        <ion-list class="mb-3 pl-2 mr-n2">
            <ion-menu-toggle
                auto-hide="false"
                v-for="(p, i) in appPages"
                :key="i"
            >
                <ion-item
                    @click="selectedIndex = i"
                    router-direction="root"
                    :router-link="p.url"
                    lines="none"
                    detail="false"
                    class="hydrated border-bottom"
                    :class="{ selected: selectedIndex === i }"
                >
                    <ion-icon
                        v-if="p.iconType === 'ionic'"
                        :ios="p.iosIcon"
                        :md="p.mdIcon"
                    ></ion-icon>
                    <span
                        v-if="p.iconType === 'fontawesome'"
                        class="d-inline-block fs-20 box-fa-align mr-2"
                        :class="{
                            'text-secondary': selectedIndex !== i,
                            'text-primary': selectedIndex === i,
                        }"
                    >
                        <font-awesome-icon :icon="p.mdIcon" />
                    </span>
                    <ion-label>{{ p.title }}</ion-label>
                    <ion-icon
                        slot="end"
                        :ios="chevronForwardOutline"
                        :md="chevronForwardSharp"
                    ></ion-icon>
                </ion-item>
            </ion-menu-toggle>
        </ion-list>

        <div class="m-3 mt-auto d-block text-center">
            <ion-text class="mb-3 d-block">
                <span>{{ profile.company && profile.company.email }}</span
                ><br />
                <span>{{ profile.name }}</span>
            </ion-text>
            <ion-button color="dark" fill="outline" @click="doLogout()">
                {{ $t("sideMenu.logoutLabel") }}
            </ion-button>
        </div>
    </div>
</template>

<script lang="ts" src="./SideMenu.ts"></script>
